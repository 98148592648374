/* Navbar styling */
.navbar {
    background-color: #0197F6; /* Blue background color for the navbar */
    padding: 0.5rem 1rem; /* Padding for the navbar */
    position: sticky; /* Sticky position */
    top: 0; /* Stick to the top of the viewport */
    z-index: 1000; /* Ensure it stays above other content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }
  
  /* Text color for navbar items */
  .text-white {
    color: white !important; /* Ensure text color is white */
  }
  
  /* Set color for nav links */
  .nav-link {
    color: white !important; /* Set color for nav links */
    font-size: 1rem; /* Font size for nav links */
    font-weight: 500; /* Medium weight for nav links */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  /* Hover color for nav links */
  .nav-link:hover {
    color: #FFD700 !important; /* Hover color for nav links */
    text-decoration: underline; /* Underline on hover */
  }
  
  /* Dropdown menu styling */
  .dropdown-menu {
    background-color: #FFFFFF; /* White background for dropdown menu */
    border: 1px solid #E0E0E0; /* Light gray border */
    border-radius: 8px; /* Rounded corners for dropdown */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Add shadow */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-up effect */
    opacity: 0; /* Start hidden */
    visibility: hidden; /* Hide by default */
    transform: translateY(10px); /* Slightly move down to enhance slide effect */
  }
  
  /* Show dropdown on hover */
  .nav-item:hover .dropdown-menu {
    opacity: 1; /* Show on hover */
    visibility: visible; /* Ensure visibility */
    transform: translateY(0); /* Slide up to visible position */
  }
  
  /* Color for dropdown items */
  .dropdown-item {
    color: #333333; /* Dark gray for dropdown items */
    padding: 0.75rem 1rem; /* Padding for dropdown items */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth background color and text color transition */
  }
  
  /* Hover color for dropdown items */
  .dropdown-item:hover {
    background-color: #F6C05C; /* Kesariya (saffron) background on hover */
    color: #FFFFFF; /* White text color on hover */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  /* Button styling */
  .get-in-touch-button {
    margin-left: 1rem; /* Margin between nav items and button */
    color: #FFFFFF; /* White text color */
    background-color: #F6C05C; /* Kesariya (saffron) background for button */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 0.4rem 0.8rem; /* Adjusted padding for the button */
    font-size: 0.9rem; /* Font size for button text */
    font-weight: 600; /* Bold text */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and text color */
  }
  
  /* Button hover effect */
  .get-in-touch-button:hover {
    background-color: #F0A500; /* Darker saffron color on hover */
    color: #FFFFFF; /* White text color on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  }
  
  /* Responsive styling */
  @media (max-width: 992px) {
    .navbar-collapse {
      text-align: center; /* Center align content on mobile */
    }
  
    .navbar-nav {
      margin: 0; /* Remove margin on mobile */
      flex-direction: column; /* Stack nav items vertically on mobile */
    }
  
    .navbar-nav .nav-link {
      margin: 0.5rem 0; /* Spacing between nav links on mobile */
      display: block; /* Display links as block elements for better spacing */
    }
  
    .get-in-touch-button {
      margin: 1rem 0; /* Margin for button on mobile */
    }
  }
  