/* Services.css */

.tp-platform-area {
  position: relative;
  overflow: hidden;
  background-color: #0197F6; /* Background color */
  color: #fff; /* Text color */
  padding: 50px 0; /* Adjust padding */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/hero-4-3.png); /* Ensure the image path is correct */
  background-size: cover;
  background-position: center;
  z-index: -1; 
  animation: rotate 20s linear infinite;
}

/* Keyframes for Rotating Animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tp-platform-section-box {
  margin-bottom: 20px; /* Margin at bottom */
}

.tp-section-subtitle-4 {
  color: #ffcb05; /* Accent color */
  font-size: 1.25rem; /* Font size for subtitle */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px; /* Spacing between letters */
  margin-bottom: 10px; /* Space below subtitle */
}

.tp-section-title-4 {
  color: #fff;
  font-size: 2.5rem; /* Font size for title */
  font-weight: bold;
  line-height: 1.2; /* Line height for title */
  margin-bottom: 20px; /* Space below title */
}

.tp-platform-text p {
  color: #e0e0e0; /* Lighter text color for better readability */
  font-size: 1rem; /* Font size for paragraph text */
  line-height: 1.6; /* Line height for paragraph text */
  padding: 10px 0; /* Padding around the paragraph */
  border-top: 1px solid #fff; /* Add a subtle border at the top */
  border-bottom: 1px solid #fff; /* Add a subtle border at the bottom */
  margin-top: 0; /* Ensure there is no margin at the top */
  margin-bottom: 20px; /* Space below paragraph */
}

.service-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add space between boxes */
  margin-top: 50px;
  position: relative; /* Ensure boxes are on top of the background */
}

.service-box {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Enhanced shadow effect */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  width: calc(25% - 20px); /* Adjust width considering gap */
  min-height: 300px; /* Ensure boxes are tall enough */
  color: #333;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px; /* Added padding inside box */
  box-sizing: border-box; /* Ensure padding does not affect width/height */
  text-align: center; /* Center text inside box */
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2); /* Enhanced hover shadow */
  background-color: #FF9933; /* Kesariya color on hover */
  color: #fff; /* Ensure text color changes to white */
}

.service-img {
  width: 100%;
  height: 150px; /* Adjusted height */
  object-fit: cover; /* Ensure the image is not cut off */
  margin-bottom: 15px; /* Space between image and text */
}

.service-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out title and description */
  height: 100%; /* Ensure it takes full height */
}

.service-title {
  font-size: 1.25rem; /* Adjusted font size */
  margin-bottom: 10px;
}

.service-description {
  font-size: 0.875rem; /* Adjusted font size */
  margin-top: auto; /* Push description to the bottom */
  line-height: 1.4; /* Line height for better readability */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .service-box {
    width: calc(33.333% - 20px); /* Adjust size for smaller screens */
  }
}

@media (max-width: 768px) {
  .service-box {
    width: calc(50% - 20px); /* Adjust size for tablets */
  }
}

@media (max-width: 576px) {
  .service-box {
    width: 100%; /* Full width for mobile devices */
  }
}
