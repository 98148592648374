/* General styles for the About section */
.about-area {
    position: relative;
    overflow: hidden;
    padding: 50px 20px;
    background: #f9f9f9;
  }
  
  .about-top-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    z-index: -1;
  }
  
  .about-top-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
  }
  
  .about-left-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    max-width: 100%;
  }
  
  .about-left-img img {
    width: 100%;
    transition: transform 0.5s ease-in-out;
    border-radius: 10px;
  }
  
  .about-left-img:hover img {
    transform: scale(1.05);
  }
  
  .about-content {
    padding: 20px;
    border-radius: 0;
    background: none;
    box-shadow: none;
    transition: transform 0.3s ease;
  }
  
  .about-content:hover {
    transform: translateY(-5px);
  }
  
  .section-subtitle {
    font-size: 1.25rem;
    color: #0197F6;
    font-weight: 500;
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  .section-subtitle:hover {
    color: #005a9c;
  }
  
  .section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 25px;
    font-weight: 700;
    transition: color 0.3s ease;
  }
  
  .section-title:hover {
    color: #0197F6;
  }
  
  .about-content p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Responsive Styles */
  @media (max-width: 992px) {
    .about-area {
      padding: 40px 15px;
    }
    
    .about-top-img {
      height: 40%;
    }
  
    .about-content {
      padding: 15px;
    }
  
    .section-title {
      font-size: 1.75rem;
    }
  
    .section-subtitle {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 768px) {
    .about-top-img {
      height: 30%;
    }
  
    .about-left-img img {
      border-radius: 5px;
    }
  
    .about-content {
      padding: 10px;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  
    .section-subtitle {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .about-area {
      padding: 30px 10px;
    }
  
    .about-top-img {
      height: 20%;
    }
  
    .section-title {
      font-size: 1.25rem;
    }
  
    .section-subtitle {
      font-size: 0.9rem;
    }
  }
  
  /* Animation for the content */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .wow.fadeInLeft {
    animation: fadeInLeft 0.9s ease-in-out;
  }
  
  .wow.fadeInRight {
    animation: fadeInRight 0.9s ease-in-out;
  }
  