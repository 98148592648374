/* hero.css */

.carousel-wrapper {
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: auto;
  filter: blur(1.5px); /* Initial blur effect */
  transition: filter 0.5s ease;
}

.carousel-item:hover img {
  filter: blur(5px); /* Blur effect on hover */
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center vertically and horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.modern-caption {
  position: relative;
}

.modern-caption .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modern-caption .content {
  position: relative;
  z-index: 2;
}

.carousel-caption h1 {
  white-space: nowrap; /* Ensure text stays on one line */
}

.carousel-caption h1 a {
  color: #fff; /* White color for the link text */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Ensure the text is bold */
}

.carousel-caption h1 a:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #f94f6e; /* Change color on hover */
}

.carousel-caption p {
  font-size: 1.25rem;
}

.carousel-caption a.btn {
  background-color: #f94f6e; /* Light tomato color */
  border-color: #f94f6e;
}

.carousel-caption a.btn:hover {
  background-color: #e03a4a;
  border-color: #e03a4a;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

/* Optional: Make sure the carousel controls are visible */
.carousel-control-prev,
.carousel-control-next {
  filter: invert(100%);
}

/* Hide carousel indicators */
.carousel-indicators {
  display: none;
}

/* Responsive Design */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .carousel-caption h1 {
    font-size: 2.5rem;
  }

  .carousel-caption p {
    font-size: 1rem;
  }

  .carousel-caption a.btn {
    padding: 0.5rem 1rem;
  }
}

/* For mobile devices */
@media (max-width: 576px) {
  .carousel-caption {
    bottom: 10px;
    padding: 10px;
  }

  .carousel-caption h1 {
    font-size: 2rem;
  }

  .carousel-caption p {
    font-size: 0.875rem;
  }

  .carousel-caption a.btn {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
