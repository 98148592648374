.inner-container{
    gap: 1.5rem;
    background: #0177f6;
    padding: 2rem;
    text-align: center;
}
.inner-container .primaryText{
    color: black;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(38, 41, 241, 0.587);
}
.inner-container .button{
    /* background: #5a73d7; */
    background: orange;
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}
.animated-text {
    display: block;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Animation for button */
  .animated-button {
    opacity: 0;
    transform: scale(0.9);
    animation: fadeInScale 1s ease-out forwards;
    transition: background-color 0.3s ease;
  }
  
  @keyframes fadeInScale {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .animated-button a {
    text-decoration: none;
    color: inherit;
  }
  
  .animated-button:hover {
    background-color: #0056b3; /* Change to your desired hover color */
  }