/* General Footer Styles */
.footer {
  background: linear-gradient(135deg, #2c2c2c, #444);
  color: #fff;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
  0% {
    background: linear-gradient(135deg, #2c2c2c, #444);
  }
  50% {
    background: linear-gradient(135deg, #444, #2c2c2c);
  }
  100% {
    background: linear-gradient(135deg, #2c2c2c, #444);
  }
}

/* Gradient Animation */
.footer:before, .footer:after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: -100%;
  left: -100%;
  z-index: 0;
  transform: rotate(45deg);
  background: rgba(255, 255, 255, 0.1);
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(45deg) translate(0, 0);
  }
  100% {
    transform: rotate(45deg) translate(50%, 50%);
  }
}

/* Footer Content */
.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  z-index: 1;
  position: relative;
}

.footer-section {
  flex: 1;
  margin: 10px;
  z-index: 1;
  position: relative;
}

/* Logo Text */
.footer-section .logo-text {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.footer-section .logo-text span {
  color: #ff9;
  margin-right: 5px;
}

/* Heading Styles */
.footer-section h2 {
  color: #ff9;
  font-size: 1.6em;
  margin-bottom: 10px;
  text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.6);
  position: relative;
  padding-bottom: 5px;
}

.footer-section h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ff9;
  transition: width 0.3s ease-in-out;
}

.footer-section h2:hover::after {
  width: 100%;
}

/* Paragraph Styles */
.footer-section p {
  color: #ddd;
  font-size: 0.9em;
  line-height: 1.6;
  margin-bottom: 15px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  transition: color 0.3s, transform 0.3s, letter-spacing 0.3s;
}

.footer-section p:hover {
  color: #fff;
  transform: translateY(-3px);
  letter-spacing: 0.5px;
}

/* Quick Links */
.footer-section.links {
  margin-left: 20px;
}

/* Footer Bottom */
.footer-bottom {
  background-color: #ff9;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #555;
  position: relative;
  z-index: 1;
}

/* Text color for footer bottom */
.footer-bottom p {
  margin: 0;
  font-size: 0.8em;
  color: black; /* Ensure text color is black */
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; /* Prevents wrapping of social icons */
  margin-bottom: 15px;
}

.social-icons a {
  color: #fff;
  margin: 0 10px; /* Adjust margin to fit with text */
  text-decoration: none;
  font-size: 1.2em; /* Adjusted size */
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 8px; /* Adjust padding to fit icon size */
  background-color: #444; /* Background color for circle */
}

.social-icons a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ff9;
  visibility: hidden;
  transition: width 0.3s ease-in-out;
}

.social-icons a:hover::before {
  visibility: visible;
  width: 100%;
}

.social-icons a:hover {
  color: #ff9;
  background-color: #0056b3; /* Blue background on hover */
  transform: scale(1.2);
  box-shadow: 0 6px 15px rgba(255, 255, 255, 0.6);
}

/* Map Container */
.map {
  width: 100%;
}

/* Anchor Tags */
.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s;
  position: relative;
  display: inline-block;
  padding: 2px 4px;
}

/* Underline Effect on Hover */
.footer-section ul li a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff9;
  visibility: hidden;
  transition: width 0.3s ease-in-out;
}

.footer-section ul li a:hover::before {
  visibility: visible;
  width: 100%;
}

/* Hover Effects */
.footer-section ul li a:hover {
  color: #ff9;
  transform: translateX(5px);
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.3);
}

/* Address Styles */
.footer-section address {
  font-style: normal;
  color: #ddd;
  font-size: 0.9em;
  margin-top: 5px;
  display: inline-block;
  transition: color 0.3s, transform 0.3s;
  position: relative;
  padding-bottom: 2px; /* Space for the underline effect */
}

.footer-section address::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff9;
  transition: width 0.3s ease-in-out;
}

.footer-section address:hover::after {
  width: 100%;
}

.footer-section address:hover {
  color: #ff9;
  transform: translateX(5px);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 10px 0;
    text-align: center;
  }

  .footer-section h2 {
    font-size: 1.4em;
    margin-bottom: 8px;
  }

  .footer-section p {
    font-size: 0.8em;
    margin-bottom: 10px;
  }

  .social-icons {
    flex-direction: row; /* Ensures icons stay in a row */
    margin-bottom: 10px;
  }

  .social-icons a {
    font-size: 1em;
    margin: 5px;
  }

  .footer-bottom {
    padding: 15px 0;
  }

  .footer-bottom p {
    font-size: 0.7em;
    color: black; /* Ensure text color is black */
  }

  /* Center the contact section on mobile */
  .footer-section.contact {
    text-align: center;
    margin-top: 20px;
  }

  .contactModes {
    margin-top: 1.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactModes .row {
    gap: 1rem;
  }

  .mode {
    width: 100%;
    padding: 0.8rem;
    border: 0.6px solid rgba(15, 14, 14, 0.143);
    gap: 0.8rem;
  }

  .mode .button {
    font-size: 0.8rem;
  }

  .mode .detail .primaryText {
    font-size: 1rem;
  }
}

/* Remove bullet points from unordered lists */
.footer-section ul {
  list-style: none; /* Removes default bullet points */
  padding: 0; 
  margin: 0; 
}

.footer-section ul li {
  margin-bottom: 10px; 
}

@media (max-width: 480px) {
  .footer-section h2 {
    font-size: 1.2em;
  }

  .footer-section p {
    font-size: 0.7em;
  }

  .social-icons a {
    font-size: 0.9em;
    padding: 6px;
  }

  .footer-bottom {
    padding: 20px 0;
  }

  .footer-bottom p {
    font-size: 0.6em;
    color: black; /* Ensure text color is black */
  }
}
