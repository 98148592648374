/* General styles for the FAQ container */
.faq-container {
  background-color: #ffffff; /* Set background to white */
  padding: 40px 10px; /* Reduce padding */
}

.faqcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: space-between;
  gap: 15px; /* Reduce gap */
  width: 100%;
  max-width: 1200px;
}

.faq-support {
  flex: 1;
  padding: 20px; /* Reduce padding */
  color: #333;
  text-align: center;
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1), -3px -3px 8px rgba(255, 255, 255, 0.9);
  animation: fadeInUp 1s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faq-support:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15), -5px -5px 15px rgba(255, 255, 255, 0.9);
}

.faq-subtitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #0197F6; /* Set color */
  text-transform: uppercase;
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.2s;
}

.faq-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.4s;
}

.faq-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 550px;
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.6s;
}

/* Animation for fading in and moving up */
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.faq-questions {
  flex: 2;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-accordion {
  width: 100%;
}

.accordion-item {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px; /* Reduce bottom margin */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.accordion-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px; /* Reduce padding */
  background: #0197F6;
  color: #ffffff;
  border-bottom: 1px solid #0178D4;
  transition: background 0.3s ease;
}

.accordion-header:hover {
  background: #0178D4;
}

.accordion-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.accordion-icon {
  font-size: 1.4rem;
  transition: transform 0.3s ease;
}

.accordion-body {
  padding: 12px; /* Reduce padding */
  background: #ffffff;
  color: #666;
  line-height: 1.6;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.accordion-body.show {
  max-height: 200px; /* Adjust based on content */
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-title {
      font-size: 1.6rem;
  }

  .faq-description {
      font-size: 0.9rem;
  }

  .accordion-title {
      font-size: 1rem;
  }

  .accordion-icon {
      font-size: 1.2rem;
  }

  .faq-layout {
      flex-direction: column;
  }
}

@media (max-width: 576px) {
  .faq-title {
      font-size: 1.4rem;
  }

  .faq-description {
      font-size: 0.8rem;
  }

  .accordion-title {
      font-size: 0.9rem;
  }

  .accordion-icon {
      font-size: 1.1rem;
  }
}
