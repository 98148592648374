/* General Styles */
.contact-area {
  position: relative;
  padding: 120px 0;
  background: #0197F6; /* Background color */
  overflow: hidden;
}

.contact-overlay {
  position: relative;
  z-index: 1;
}

.contact-glob-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.contact-glob-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  animation: rotate 20s linear infinite; /* Rotate animation */
}

/* Keyframes for rotation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  z-index: 1;
}

.contact-section-box {
  margin-bottom: 25px;
}

.section-subtitle {
  font-size: 1.2em;
  color: #fff; /* Text color */
  margin-bottom: 10px;
}

.section-title {
  font-size: 2.5em;
  color: #fff; /* Text color */
  font-weight: bold;
}

/* Contact Info Styles */
.contact-info-box {
  margin-bottom: 30px;
}

.contact-info-box ul {
  list-style: none;
  padding: 0;
}

.contact-info-box li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-icon {
  font-size: 24px;
  color: #f8ff35;
  margin-right: 15px;
}

.contact-info-box a {
  text-decoration: none;
  color: #fff; /* Text color */
  font-size: 1em;
}

.social-links {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 24px; /* Adjusted font size for better fit */
  color: #0197F6; /* Icon color */
  background: #fff; /* Circle background color */
  border-radius: 50%; /* Circular shape */
  padding: 12px; /* Space inside the circle */
  width: 50px; /* Width of the circle */
  height: 50px; /* Height of the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow */
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.social-icon:hover {
  background-color: #ff6f6f; /* Light tomato background on hover */
  color: #fff; /* Icon color on hover */
  transform: scale(1.1); /* Scale effect on hover */
}

/* Contact Form Styles */
.contact-form-wrapper {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.contact-form-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 0.9em;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #f8ff35;
  box-shadow: 0 0 8px rgba(248, 255, 53, 0.2);
}

.submit-button {
  background-color: #f8ff35; /* Button color */
  color: #333;
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #ff6f6f; /* Light tomato background on hover */
  color: #fff; /* Text color on hover */
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-section-box {
    margin-bottom: 15px;
  }

  .section-title {
    font-size: 2em;
  }

  .section-subtitle {
    font-size: 1em;
  }

  .contact-info-box li {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-icon {
    margin-bottom: 10px;
  }

  .contact-form-wrapper {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5em;
  }

  .section-subtitle {
    font-size: 0.9em;
  }

  .contact-info-box li {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-icon {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .contact-form-wrapper {
    padding: 15px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.8em;
  }

  .submit-button {
    font-size: 0.9em;
    padding: 10px 18px;
  }
}
