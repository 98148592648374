/* product.css */

.p-wrapper {
  padding: 50px 0; /* Adjust padding */
  background-color: #0197F6; /* Light background color */
}

.p-container {
  max-width: 1200px; /* Ensure max-width for container */
  margin: 0 auto; /* Center the container */
}

.p-head {
  text-align: center; /* Center text */
  margin-bottom: 40px; /* Space below heading */
}

.highlightText {
  color: #FF6F00; /* Modern orange color */
  font-size: 1.5rem; /* Increase font size */
  font-weight: 600; /* Semi-bold weight */
}

.mainText {
  color: #333; /* Dark text color */
  font-size: 2.5rem; /* Larger font size for title */
  font-weight: 700; /* Bold weight */
}

.flexColStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to start */
}

.flexColCenter {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.p-card {
  background: #fff; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px; /* Padding inside card */
  text-align: center; /* Center text */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.p-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.p-card img {
  width: 100%;
  height: auto; /* Adjust height */
  border-radius: 10px; /* Match border-radius of card */
}

.mainText {
  font-size: 1.25rem; /* Adjust font size */
  font-weight: 700; /* Bold weight */
  color: #333; /* Dark text color */
  margin-top: 10px; /* Space above text */
}

.subText {
  font-size: 1rem; /* Adjust font size */
  color: #666; /* Lighter text color */
  margin-top: 5px; /* Space above text */
}

.p-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Space buttons */
  transform: translateY(-50%); /* Center vertically */
}

.p-buttons button {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for buttons */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.p-buttons button:hover {
  background-color: #FF6F00; /* Highlight color on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.p-buttons button:focus {
  outline: none; /* Remove outline */
}
